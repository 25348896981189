import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// components
import LoadingScreen from '../components/LoadingScreen';
import UserList from "../pages/dashboard/UserList";
import UserCreate from "../pages/dashboard/UserCreate";
import UserAccount from "../pages/dashboard/UserAccount";
import Admin from "../pages/dashboard/Admin";
import AdminInfodent from "../pages/dashboard/AdminInfodent";
import PlanVariantCreate from "../pages/dashboard/PlanVariantCreate";

// ----------------------------------------------------------------------

const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'reset-password', element: <ResetPassword /> },
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/dashboard/clients" replace /> },
        {
          path: 'clients',
          children : [
            {element: <ClientList />},
            { path: 'new', element: <ClientCreate /> },
            { path: ':id/teeth', element: <ClientTeeth /> },
            { path: ':id/infodent', element: <ClientInfodent /> },
            { path: ':id/sidexis', element: <ClientSidexis /> },
            { path: ':id/diagnocat', element: <ClientDiagnocat /> },
            { path: ':id/diana', element: <ClientDiana /> },
            { path: ':id/files', element: <ClientLocalFiles /> },
          ]
        },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':id/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'admin',
          children : [
            {element: <Admin />},
          ]
        },
        {
          path: 'infodent',
          children : [
            { element: <AdminInfodent />},
          ]
        },
        {
          path: 'plan',
          children : [
            { element: <Navigate to="/404" replace /> },
            { path: ':clientId', element: <PlanClient /> },
          ]
        },
        {
          path: 'planadmin',
          children : [
            { element: <PlanVariantList /> },
            { path: 'new', element: <PlanVariantCreate /> },
            { path: ':id/edit', element: <PlanVariantCreate /> },
          ]
        },
        {
          path: 'dicom',
          children : [
            { element: <Navigate to="/404" replace /> },
            { path: ':clientId', element: <DicomClient /> },
          ]
        },
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/dashboard" replace /> },
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
// Dashboard
const ClientList = Loadable(lazy(() => import('../pages/dashboard/ClientList')));
const ClientCreate = Loadable(lazy(() => import('../pages/dashboard/ClientCreate')));
const ClientTeeth = Loadable(lazy(() => import('../pages/dashboard/ClientTeeth')));
const ClientInfodent = Loadable(lazy(() => import('../pages/dashboard/ClientInfodentPage')));
const ClientSidexis = Loadable(lazy(() => import('../pages/dashboard/ClientSidexisPage')));
const ClientDiagnocat = Loadable(lazy(() => import('../pages/dashboard/ClientDiagnocatPage')));
const ClientDiana = Loadable(lazy(() => import('../pages/dashboard/ClientDianaPage')));
const ClientLocalFiles = Loadable(lazy(() => import('../pages/dashboard/ClientLocalFiles')));
const PlanClient = Loadable(lazy(() => import('../pages/dashboard/PlanClient')));
const PlanVariantList = Loadable(lazy(() => import('../pages/dashboard/PlanVariantList')));
const DicomClient = Loadable(lazy(() => import('../pages/dashboard/DicomClient')));

// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
