import { Ability, AbilityBuilder } from '@casl/ability';
import {AuthUser} from "../@types/authentication";
import {PATH_AUTH, PATH_DASHBOARD} from "../routes/paths";
import {Roles} from "./roles";

export default function defineAbilityFor(user?: AuthUser) {
    const { can, rules } = new AbilityBuilder(Ability);
    const role = user?.role;

    if(role === Roles.admin) {
        can(['view', 'delete'], 'all');
    }

    if(role === Roles.doctor) {
        can('view', [PATH_DASHBOARD.clients.root, PATH_DASHBOARD.clients.new, PATH_DASHBOARD.dicom]);
    }

    can('view', [PATH_DASHBOARD.root, PATH_DASHBOARD.user.account, PATH_AUTH.login, PATH_AUTH.resetPassword]);

    return new Ability(rules as any);
}
