import { createSlice } from '@reduxjs/toolkit';
import {IndexResult} from "../../@types/admin";
import {Dict} from "../../@types/dict";

// ----------------------------------------------------------------------

type AdminState = {
  isLoading: boolean;
  error: boolean;
  indexResult: IndexResult[];
  dictUpdateResult: Dict[];
  infodentSelectResult: any[];
};

const initialState: AdminState = {
  isLoading: false,
  error: false,
  indexResult: [],
  dictUpdateResult: [],
  infodentSelectResult: [],
};

const slice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.indexResult = [];
      state.dictUpdateResult = [];
    },

    finishLoading(state) {
      state.isLoading = false;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    indexSuccess(state, action) {
      state.isLoading = false;
      state.indexResult = action.payload;
    },

    dictUpdateSuccess(state, action) {
      state.isLoading = false;
      state.dictUpdateResult = action.payload;
    },

    infodentSelectSuccess(state, action) {
      state.isLoading = false;
      state.infodentSelectResult = action.payload;
    },
  }
});

export const { startLoading, finishLoading, hasError, indexSuccess, dictUpdateSuccess, infodentSelectSuccess } = slice.actions;

export default slice.reducer;


