import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
} from '@mui/material';
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store';
import {deleteUserThunk, getUserListThunk} from "../../redux/thunks/user";
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// @types
import { UserManager } from '../../@types/user';
// components
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import { UserListHead, UserMoreMenu } from '../../components/user/list';
import ConfirmDialog from "../../components/ConfirmDialog";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'ФИО', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'role', label: 'Роль', alignRight: false },
  { id: 'position', label: 'Должность', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function UserList() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();

  const { userList } = useSelector((state: RootState) => state.user);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [selected, ] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState<UserManager | undefined>(undefined);

  useEffect(() => {
    dispatch(getUserListThunk());
  }, [dispatch]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDeleteUser = (user: UserManager) => {
    setOpenDeleteConfirm(user);
  };

  const handleDeleteUserClose = async (confirm: boolean) => {
    if (confirm && openDeleteConfirm) {
      dispatch(deleteUserThunk(openDeleteConfirm?.id));
    }
    setOpenDeleteConfirm(undefined);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userList.length) : 0;

  return (
    <Page title="Список пользователей">
      <Container maxWidth={themeStretch ? false : 'lg'}>

        <ConfirmDialog
            title="Удалить пользователя?"
            text={`Вы действительно хотите удалить пользователя "${openDeleteConfirm?.last_name} ${openDeleteConfirm?.first_name}"?`}
            open={ openDeleteConfirm !== undefined }
            onClose={ handleDeleteUserClose }
        />

        <HeaderBreadcrumbs
          heading="Список пользователей"
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Пользователи', href: PATH_DASHBOARD.user.root },
          ]}
          action={
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.user.newUser}
              startIcon={<Icon icon={plusFill} />}
            >
              Создать
            </Button>
          }
        />

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, marginTop: 1 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {userList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, first_name, last_name, email, role, position } = row;
                      const isItemSelected = selected.indexOf(first_name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {`${last_name} ${first_name}`}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{role.name}</TableCell>
                          <TableCell align="left">{position}</TableCell>

                          <TableCell align="right">
                            <UserMoreMenu onDelete={() => handleDeleteUser(row)} userId={id} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={5} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 20, 50]}
            component="div"
            count={userList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={(e) => handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
