import {AppThunk} from "../store";
import ApiClients from "../../utils/axios";

const { axiosBase } = ApiClients;

export const changePasswordThunk = (
    currentPassword: string,
    password: string,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const params = {
            currentPassword,
            password,
        }
        await axiosBase.post(`/auth/change_password`, params);
        return true;
    } catch (error) {
    }
    return false;
}