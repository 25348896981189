import {AppThunk} from "../store";
import {DentalFormula} from "../../@types/dentalFormula";
import {getStatusListSuccess, getToothListSuccess, hasError, startLoading} from "../slices/formula";
import {refreshClientFormula} from "../slices/client";
import ApiClients from "../../utils/axios";

const { axiosBase } = ApiClients;

export const getToothListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get('/dental/teeth');
        dispatch(getToothListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getStatusListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get('/dental/statuses');
        dispatch(getStatusListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const saveClientFormulaThunk = (
    id: number,
    items: DentalFormula[]
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.post<DentalFormula>(`/client/${id}/formula`, {
            items: items.map(f => ({
                id: f.id,
                tooth_id: f.tooth.id,
                part: f.part,
                status_id: f.status.id
            }))
        })
        dispatch(refreshClientFormula(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}
