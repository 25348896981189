// material
import {Table, TableBody, TableCell, TableHead, TableRow} from '@mui/material';

import React from "react";
import {PlanService, PlanVariantLine} from "../../../../@types/plan";
import VariantLineRow from "./VariantLineRow";
import ServiceSelector from "./ServiceSelector";

// ----------------------------------------------------------------------

type Props = {
    lines: PlanVariantLine[]
    onChange: (lines: PlanVariantLine[]) => void
};

export default function VariantLinesEditor({
    lines,
    onChange,
}: Props) {

    const handleLineChange = (ind: number, data: PlanVariantLine) => {
        onChange(lines.map((line, i) =>  (i === ind) ? data : line))
    }

    const handleLineRemove = (ind: number) => {
        onChange(lines.filter((line, i) => i !== ind).map((line, i) => ({
            ...line,
            sort: i,
        })))
    }

    const handleServiceAdd = (service: PlanService) => {
        onChange(lines.concat([{
            service,
            count: 1,
            sort: lines.length,
        }]))
    }

    return (
        <>
            {lines.length > 0 && (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Услуга</TableCell>
                            <TableCell>Цена, р</TableCell>
                            <TableCell>Количество, шт</TableCell>
                            <TableCell>&nbsp;</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lines.map((line, i) => (
                            <VariantLineRow
                                line={line}
                                onChange={(l: PlanVariantLine) => handleLineChange(i, l)}
                                onRemove={() => handleLineRemove(i)}
                            />
                        ))}
                    </TableBody>
                </Table>
            )}
            <ServiceSelector
                lines={lines}
                handleSelect={handleServiceAdd}
            />
        </>
    );
}
