import {AppThunk} from "../store";
import {deleteUser, getUserListSuccess, getRoleListSuccess, hasError, startLoading} from "../slices/user";
import ApiClients from "../../utils/axios";

const { axiosBase } = ApiClients;

export const getUserListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get('/user/all');
        dispatch(getUserListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getRoleListThunk = ():AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get('/user/roles');
        dispatch(getRoleListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const deleteUserThunk = (
    userId: string
):AppThunk => async dispatch => {
    try {
        await axiosBase.delete(`/user/${userId}`);
        dispatch(deleteUser(userId));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const createUserThunk = (
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axiosBase.post(`/user/create`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const editUserThunk = (
    userId: string,
    params: any,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        await axiosBase.put(`/user/${userId}`, params);
        return true;
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}


