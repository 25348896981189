import {AppThunk} from "../store";
import ApiClients from "../../utils/axios";
import {LocalOpenResult, LocalTestResult} from "../../@types/local";
import {PostFile} from "../../@types/files";

const { axiosLocal } = ApiClients;

export const getLocalVersionThunk = (
):AppThunk<Promise<LocalTestResult>> => async dispatch => {
    try {
        const response = await axiosLocal.get(`/version`);
        return {
            available: true,
            data: response.data
        };
    } catch (error) {
    }
    return {
        available: false
    };
}

export const openLocalFileThunk = (
    path: number,
    file: string,
    title: string
):AppThunk<Promise<LocalOpenResult>> => async dispatch => {
    try {
        const url = `/files/local?path=${path}&file=${file}&title=${title}`;
        console.log(`request: ${url}`);
        const response = await axiosLocal.get(url);
        return {
            available: true,
            data: response.data
        };
    } catch (error) {
    }
    return {
        available: false
    };
}

export const uploadLocalFileThunk = (
    file: File,
):AppThunk<Promise<PostFile | void>> => async dispatch => {
    try {
        const formData = new FormData();
        formData.append(`file`, file);

        const response = await axiosLocal.post<PostFile>(`/files/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
