import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, Card, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// utils
import {PATH_DASHBOARD} from "../../../routes/paths";
import {useNavigate} from "react-router-dom";
import {dispatch} from "../../../redux/store";
import {changePasswordThunk} from "../../../redux/thunks/auth";

// ----------------------------------------------------------------------

export default function AccountChangePassword() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const ChangePassWordSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Введите старый пароль'),
    password: Yup.string()
      .required('Введите новый парроль')
      .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{12,})/,
          "Должен содержать минимум 12 символов латинского алфавита, заглавные, прописные, символы и спецсимволы"
      ),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Пароли не совпадают')
  });

  const formik = useFormik({
    initialValues: {
      currentPassword: '',
      password: '',
      confirmNewPassword: ''
    },
    validationSchema: ChangePassWordSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const result = await dispatch(changePasswordThunk(values.currentPassword, values.password));
      setSubmitting(false);
      if(result){
        enqueueSnackbar('Пароль успешно изменен', { variant: 'success' });
        navigate(PATH_DASHBOARD.user.account);
      }
      else {
        enqueueSnackbar("Ошибка изменения пароля", { variant: 'error' });
      }
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Card sx={{ p: 3 }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} alignItems="flex-end">
            <TextField
              {...getFieldProps('currentPassword')}
              fullWidth
              autoComplete="on"
              type="password"
              label="Старый пароль"
              error={Boolean(touched.currentPassword && errors.currentPassword)}
              helperText={touched.currentPassword && errors.currentPassword}
            />

            <TextField
              {...getFieldProps('password')}
              fullWidth
              autoComplete="on"
              type="password"
              label="Новый пароль"
              error={Boolean(touched.password && errors.password)}
              helperText={
                (touched.password && errors.password) || 'Пароль должен быть длиной минимум 12 символов латинского алфавита, заглавные, прописные, символы и спецсимволы'
              }
            />

            <TextField
              {...getFieldProps('confirmNewPassword')}
              fullWidth
              autoComplete="on"
              type="password"
              label="Повторить новый пароль"
              error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
              helperText={touched.confirmNewPassword && errors.confirmNewPassword}
            />

            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Изменить пароль
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </Card>
  );
}
