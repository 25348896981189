import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector, RootState } from '../../redux/store';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import {getPlanServiceGroupListThunk, getPlanServiceListThunk, getPlanVariantListThunk} from "../../redux/thunks/plan";
import {getStatusListThunk} from "../../redux/thunks/formula";
import VariantForm from "../../components/plan/admin/VariantForm";

// ----------------------------------------------------------------------

export default function PlanVariantCreate() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id = '' } = useParams();
  const { variantList } = useSelector((state: RootState) => state.plan);
  const isEdit = pathname.includes('edit');
  const currentVariant = variantList.find((variant) => variant.id && (+variant.id === +id));

  useEffect(() => {
    dispatch(getPlanVariantListThunk());
    dispatch(getPlanServiceListThunk());
    dispatch(getPlanServiceGroupListThunk());
    dispatch(getStatusListThunk());
  }, [dispatch]);

  return (
    <Page title="Редактирование варианта лечения">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Создание варианта лечения' : 'Редактирование варианта лечения'}
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Варианты лечения', href: PATH_DASHBOARD.planadmin.root },
            { name: !isEdit ? 'Новый вариант лечения' : id }
          ]}
        />

        <VariantForm
            isEdit={isEdit}
            current={currentVariant}
        />

      </Container>
    </Page>
  );
}
