import {AppThunk} from "../store";
import {
    getPlanListSuccess,
    addPlan,
    hasError,
    startLoading,
    addPlanItem,
    getPlanVariantListSuccess,
    getPlanServiceListSuccess,
    editPlanItem,
    deletePlanItem,
    deletePlanVariant,
    addPlanVariant,
    editPlanVariant,
    getPlanServiceGroupListSuccess,
    removePlan
} from "../slices/plan";
import ApiClients from "../../utils/axios";
import {Plan, PlanItem, PlanVariant} from "../../@types/plan";

const { axiosBase } = ApiClients;

export const getPlanListThunk = (
    clientId: number
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get(`/plan?client_id=${clientId}`, {});
        dispatch(getPlanListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const createPlanThunk = (
    clientId: number,
    title: string,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.post<Plan>(`/plan`, {
            client_id: clientId,
            title: title,
        })
        dispatch(addPlan(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const removePlanThunk = (
    planId: number,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.delete<Plan>(`/plan/${planId}`, )
        dispatch(removePlan(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const createPlanItemThunk = (
    teeth: number[],
    data: PlanItem,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.post<Plan>(`/plan/item`, {
            ...data,
            teeth: teeth.map(toothId => ({
                tooth_id: toothId
            })),
        })
        dispatch(addPlanItem(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const editPlanItemThunk = (
    data: PlanItem,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.put<Plan>(`/plan/item/${data.id}`, data)
        dispatch(editPlanItem(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const deletePlanItemThunk = (
    itemId: number,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.delete<Plan>(`/plan/item/${itemId}`)
        dispatch(deletePlanItem(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const getPlanVariantListThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get(`/plan/variants`, {});
        dispatch(getPlanVariantListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const createPlanVariantThunk = (
    data: PlanVariant,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.post<Plan>(`/plan/variants`, data)
        dispatch(addPlanVariant(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const editPlanVariantThunk = (
    data: PlanVariant,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.put<Plan>(`/plan/variants/${data.id}`, data)
        dispatch(editPlanVariant(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const deletePlanVariantThunk = (
    id: number,
):AppThunk<Promise<boolean>> => async dispatch => {
    try {
        const response = await axiosBase.delete<Plan>(`/plan/variants/${id}`)
        dispatch(deletePlanVariant(response.data))
        return true;
    } catch (error) {
        dispatch(hasError(error))
    }
    return false;
}

export const getPlanServiceListThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get(`/plan/services`, {});
        dispatch(getPlanServiceListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const getPlanServiceGroupListThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get(`/plan/servicegroups`, {});
        dispatch(getPlanServiceGroupListSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}
