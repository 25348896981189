import {NodeModel, Tree} from "@minoru/react-dnd-treeview";
import styles from "./ServiceSelector.module.css";
import {RootState, useSelector} from "../../../../redux/store";
import React, {useMemo, useState} from "react";
import {PlanService, PlanVariantLine} from "../../../../@types/plan";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Button, Grid, IconButton, TextField, Typography} from "@mui/material";
import {SearchOutlined} from "@mui/icons-material";

type Props = {
    lines: PlanVariantLine[];
    handleSelect: (service: PlanService) => void;
}

type Node = {
    isFolder: boolean;
    price?: number;
    selected?: boolean;
}


export default function ServiceSelector({ lines, handleSelect }: Props) {

    const { serviceList, serviceGroupList } = useSelector((state: RootState) => state.plan)

    const [search, setSearch] = useState<string>('')

    const handleAdd = (id: string | number) => {
        const service = serviceList.find(s => s.id === +id)
        if(service) {
            handleSelect(service)
        }
    }

    const treeData = useMemo(() => {
        const searchString = search.length > 0 ? search.toLowerCase() : undefined
        const common : NodeModel<Node>[] = [{
            id: -1000000,
            parent: 0,
            text: 'Общие',
            data: {
                isFolder: true,
            }
        }]
        const groups = serviceGroupList.slice().sort((g1, g2) => g1.title.localeCompare(g2.title))
        const services = serviceList.slice().filter(s => !searchString || s.title.toLowerCase().includes(searchString)).sort((s1, s2) => s1.title.localeCompare(s2.title))
        const result = common.concat(groups.map(group => ({
            id: -group.id,
            parent: -(group.parent_id || 0),
            text: group.title,
            data: {
                isFolder: true,
            }
        }))).concat(services.map(service => ({
            id: service.id,
            parent: -(service.group_id || 1000000),
            text: service.title,
            data: {
                isFolder: false,
                price: service.price,
                selected: !!lines.find(line => line.service.id === service.id)
            }
        })))
        const step1 =  result.filter(node => !node.data?.isFolder || result.reduce((acc, n) => acc + (n.parent === node.id ? 1 : 0), 0) > 0)
        return step1.filter(node => !node.data?.isFolder || step1.reduce((acc, n) => acc + (n.parent === node.id ? 1 : 0), 0) > 0)
    }, [search, lines, serviceList, serviceGroupList])

    return (
        <>
            <TextField
                fullWidth
                variant="outlined"
                placeholder="Поиск по услугам"
                InputProps={{
                    endAdornment: (
                        <IconButton>
                            <SearchOutlined />
                        </IconButton>
                    ),
                }}
                onChange={e => setSearch(e.target.value)}
            />
            {treeData.length > 0 ? (
                <Tree
                    tree={treeData}
                    rootId={0}
                    render={(node, { depth, isOpen, onToggle }) => (
                        <Grid container>
                            <Grid item xs={8}>
                                <div
                                    className={styles.row}
                                    style={{ paddingInlineStart: depth * 24 }}
                                    onClick={onToggle}
                                >
                                    {node.data?.isFolder && (isOpen ? <RemoveIcon className={styles.icon}/> : <AddIcon className={styles.icon}/>)}
                                    <Typography variant="body1" className={styles.title}>{node.text}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={2}>
                                {node.data?.price && (
                                    <Typography variant="body1" className={styles.title}>{node.data?.price} р</Typography>
                                )}
                            </Grid>
                            <Grid item xs={2}>
                                {!node.data?.isFolder && (
                                    <Button
                                        variant="contained"
                                        size="small"
                                        sx={{mb:1}}
                                        onClick={() => handleAdd(node.id)}
                                        disabled={node.data?.selected}
                                    >
                                        Выбрать
                                    </Button>
                                )}
                            </Grid>
                        </Grid>
                    )}
                    classes={{
                        root: styles.treeRoot,
                        listItem: styles.listItem,
                        draggingSource: styles.draggingSource,
                        placeholder: styles.placeholder
                    }}
                    sort={false}
                    insertDroppableFirst={false}
                    onDrop={() => {}}
                />
            ) : (
                <Typography variant="body1">ничего не найдено</Typography>
            )}
        </>
    );
}