import { createSlice } from '@reduxjs/toolkit';
import {Client, ClientFile, ClientRefData, FullClient} from "../../@types/client";
import {FileOrder, RemoveFile} from "../../@types/files";
import {Dict} from "../../@types/dict";

// ----------------------------------------------------------------------

type ClientState = {
    isLoading: boolean;
    searchInProgress: boolean;
    needAdditionalLoading: boolean;
    error: boolean;
    clientList: Client[] | null;
    clientInfo: FullClient | null;
    clientFiles: ClientFile[];
    clientRefs: ClientRefData[];
    doctorList: Dict[];
    serviceList: Dict[];
};

const initialState: ClientState = {
    isLoading: false,
    searchInProgress: false,
    needAdditionalLoading: false,
    error: false,
    clientList: null,
    clientInfo: null,
    clientFiles: [],
    clientRefs: [],
    doctorList: [],
    serviceList: [],
};

const slice = createSlice({
    name: 'client',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        startSearch(state) {
            state.searchInProgress = true;
        },

        cancelSearch(state) {
            state.searchInProgress = false;
        },

        getListSuccess(state, action) {
            state.searchInProgress = false;
            state.needAdditionalLoading = true;
            state.clientList = action.payload;
        },

        getAdditionalSuccess(state, action) {
            state.isLoading = false;
            const id = action.payload.id;
            const oldRefs = state.clientList?.find(client => client.import_id === id)?.refs;
            if(oldRefs) {
                const importer = action.payload.importer;
                const status = action.payload.status;
                const importId = action.payload.import_id;

                const newRefs = oldRefs.filter(ref => ref.type !== importer);
                newRefs.push({
                    status,
                    type: importer,
                    import_id: importId,
                })

                state.clientList = state.clientList?.map(client => client.import_id === id ? {
                    ...client,
                    refs: newRefs,
                } : client) || null
            }
        },

        setAdditionalStarted(state) {
            state.needAdditionalLoading = false;
        },

        clearClientInfo(state, action) {
            state.clientInfo = null;
            state.clientFiles = [];
            state.clientRefs = [];
        },

        getInfoSuccess(state, action) {
            state.isLoading = false;
            state.clientInfo = action.payload;
        },

        getClientImportSuccess(state, action) {
            state.isLoading = false;
            const type = action.payload.importer;
            state.clientRefs = [...state.clientRefs.filter(r => r.type !== type), {
                type,
                import_id: action.payload.import_id,
                data: action.payload.data,
            }]
        },

        deleteClientImportSuccess(state, action) {
            const type = action.payload.importer;
            state.clientRefs = state.clientRefs.filter(r => r.type !== type)
        },

        getClientFilesSuccess(state, action) {
            state.isLoading = false;
            state.clientFiles = action.payload;
        },

        updateClientFiles(state, action) {
            if(state.clientInfo) {
                state.clientFiles = action.payload;
            }
        },

        addClientFile(state, action) {
            if(state.clientInfo) {
                const files = state.clientFiles.concat([action.payload]);
                state.clientFiles = files;
            }
        },

        deleteClientFile(state, action) {
            if(state.clientInfo) {
                const files = state.clientFiles.filter(file => file.id !== action.payload);
                state.clientFiles = files;
            }
        },

        updateFileFolder(state, action) {
            if(state.clientInfo) {
                const removeFileToFolder: RemoveFile[] = action.payload
                const files = state.clientFiles.map(file => {
                    const removeFile = removeFileToFolder.find(removeFileToFolder => removeFileToFolder.id === file.id);
                    return {
                        ...file,
                        parent_id: removeFile ? removeFile?.parent_id : file.parent_id,
                    }
                });
                state.clientFiles = files;
            }
        },

        updateClientFileSortOrder(state, action) {
            if(state.clientInfo && state.clientFiles.length > 0) {
                const order: FileOrder[] = action.payload
                const files = state.clientFiles.map(file => {
                    const fileOrder = order.find(order => order.id === file.id);
                    return {
                        ...file,
                        parent_id: fileOrder ? fileOrder?.parent_id : file.parent_id,
                        sort_order: fileOrder? fileOrder?.sort_order : file.sort_order,
                    }
                });
                state.clientFiles = files;
            }
        },

        addClientFormula(state, action) {
            if(state.clientInfo) {
                const formula = state.clientInfo.dental_formula.concat([action.payload]);
                state.clientInfo.dental_formula = formula;
            }
        },

        deleteClientFormula(state, action) {
            if(state.clientInfo) {
                const formula = state.clientInfo.dental_formula.filter(f => f.tooth.id !== action.payload.tooth.id || f.status.id !== action.payload.status.id || f.part !== action.payload.part);
                state.clientInfo.dental_formula = formula;
            }
        },

        refreshClientFormula(state, action) {
            if(state.clientInfo) {
                state.clientInfo.dental_formula = action.payload;
            }
        },

        getDoctorListSuccess(state, action) {
            state.isLoading = false;
            state.doctorList = action.payload;
        },

        getServiceListSuccess(state, action) {
            state.isLoading = false;
            state.serviceList = action.payload;
        }
    }
});

export const { startLoading, hasError, startSearch, cancelSearch, getListSuccess, getAdditionalSuccess, setAdditionalStarted, clearClientInfo, getInfoSuccess,
    getClientImportSuccess, deleteClientImportSuccess,
    getClientFilesSuccess, updateClientFiles, addClientFile, deleteClientFile, updateFileFolder, updateClientFileSortOrder,
    addClientFormula, deleteClientFormula, refreshClientFormula,
    getDoctorListSuccess, getServiceListSuccess} = slice.actions;

export default slice.reducer;


