import * as Yup from 'yup';
import {useSnackbar} from 'notistack';
import {useNavigate} from 'react-router-dom';
import {Form, FormikProvider, useFormik} from 'formik';
// material
import {LoadingButton} from '@mui/lab';
import {Box, Card, Grid, Stack, TextField} from '@mui/material';
//
import {PLAN_TYPES, PlanVariant, PlanVariantLine, PlanVariantType} from "../../../@types/plan";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {createPlanVariantThunk, editPlanVariantThunk} from "../../../redux/thunks/plan";
import {useEffect, useState} from "react";
import {RootState, useDispatch, useSelector} from "../../../redux/store";
import VariantLinesEditor from "./line/VariantLinesEditor";

// ----------------------------------------------------------------------

type Props = {
  isEdit: boolean;
  current?: PlanVariant;
};

export default function VariantForm({ isEdit, current }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const { statusList } = useSelector((state: RootState) => state.formula)

  const [lines, setLines] = useState<PlanVariantLine[]>([])

  useEffect(() => {
    setLines(current?.lines || [])
  }, [current])

  const VariantSchema = Yup.object().shape({
    title: Yup.string().required('Название обязательно для заполнения'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: current?.id,
      title: current?.title || '',
      type: current?.type || PlanVariantType.CHEAP,
      status_id: current?.status?.id || 0,
    },
    validationSchema: VariantSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      if(lines.length === 0) {
        enqueueSnackbar("Список услуг не может быть пустым", { variant: 'error' });
      }
      else {
        const params = {
          ...values,
          status: statusList.find(status => status.id === +values.status_id),
          lines,
        }
        const result = isEdit
            ? await dispatch(editPlanVariantThunk(params))
            : await dispatch(createPlanVariantThunk(params));

        setSubmitting(false);
        if(result){
          resetForm();
          enqueueSnackbar(!isEdit ? 'Вариант лечения успешно добавлен' : 'Вариант лечения успешно сохранен', { variant: 'success' });
          navigate(PATH_DASHBOARD.planadmin.root);
        }
        else {
          enqueueSnackbar("Ошибка выполнения запроса к серверу", { variant: 'error' });
        }
      }
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <TextField
                    fullWidth
                    label="Название"
                    {...getFieldProps('title')}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                    select
                    fullWidth
                    label="Тип"
                    {...getFieldProps('type')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.type && errors.type)}
                    helperText={touched.type && errors.type}
                >
                  {PLAN_TYPES.map((type, i) => (
                      <option key={i} value={type.name}>
                        {type.title}
                      </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6} md={6}>
                <TextField
                    select
                    fullWidth
                    label="Статус"
                    {...getFieldProps('status_id')}
                    SelectProps={{ native: true }}
                    error={Boolean(touched.status_id && errors.status_id)}
                    helperText={touched.status_id && errors.status_id}
                >
                  <option value={0}>&nbsp;</option>
                  {statusList.map(status => (
                      <option key={status.id} value={status.id}>
                        {status.title}
                      </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Stack spacing={3} mt={3}>
              <VariantLinesEditor
                  lines={lines}
                  onChange={setLines}
              />
            </Stack>
          </Card>

        <Box sx={{ mb: 3 }} />
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {!isEdit ? 'Создать' : 'Сохранить'}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
