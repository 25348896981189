// material
import {
    Box, Card,
    Container, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    TextField, Typography,
} from '@mui/material';
// components
import Page from '../../components/Page';
import useSettings from "../../hooks/useSettings";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {PATH_DASHBOARD} from "../../routes/paths";
import {Form, FormikProvider, useFormik} from "formik";
import {RootState, useDispatch, useSelector} from "../../redux/store";
import React, {useMemo} from "react";
import {infodentFindListThunk, infodentSelectListThunk} from "../../redux/thunks/admin";

type FormValuesProps = {
    query: string,
};

type FormSearchValuesProps = {
    search: string,
};

export default function AdminInfodent() {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();

    const { isLoading, infodentSelectResult } = useSelector((state: RootState) => state.admin)

    const formik = useFormik<FormValuesProps>({
        enableReinitialize: true,
        initialValues: {
            query: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            dispatch(infodentSelectListThunk(values.query))
        }
    });

    const { errors, touched, handleSubmit, getFieldProps } =
        formik;

    const keys = useMemo(() => {
        return infodentSelectResult.length > 0 ? Object.keys(infodentSelectResult[0]) : [];
    }, [infodentSelectResult])

    const formik2 = useFormik<FormSearchValuesProps>({
        enableReinitialize: true,
        initialValues: {
            search: '',
        },
        onSubmit: async (values, { setSubmitting }) => {
            dispatch(infodentFindListThunk(values.search))
        }
    });

    const { errors: errors2, touched: touched2, handleSubmit: handleSubmit2, getFieldProps: getFieldProps2 } =
        formik2;

    return (
        <Page title="Администрирование">
          <Container maxWidth={themeStretch ? false : 'lg'}>
              <HeaderBreadcrumbs
                  heading="Администрирование"
                  links={[
                      { name: 'Главная', href: PATH_DASHBOARD.root },
                      { name: 'Инфодент', href: PATH_DASHBOARD.infodent.root },
                  ]}
              />
              <Typography>SQL запрос</Typography>
              <Box sx={{ mb: 3 }} />
              <FormikProvider value={formik}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                      <TextField
                          fullWidth
                          label="Запрос"
                          {...getFieldProps('query')}
                          error={Boolean(touched.query && errors.query)}
                          helperText={touched.query && errors.query}
                      />
                  </Form>
              </FormikProvider>
              <Box sx={{ mb: 3 }} />

              {!isLoading && infodentSelectResult.length === 0 && (
                  <div>Ничего не найдено</div>
              )}

              {!isLoading && keys.length > 0 && (
                  <Card>
                      <TableContainer sx={{ minWidth: 800, marginTop: 1 }}>
                          <Table>
                              <TableHead>
                                  <TableRow>
                                      {keys.map((key, j) => (
                                          <TableCell key={`head-${j}`}>{key}</TableCell>
                                      ))}
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {infodentSelectResult.map((row, i) => (
                                      <TableRow key={`row-${i}`}>
                                          {keys.map((key, j) => (
                                              <TableCell key={`cell-${i}-${j}`}>{row[key]}</TableCell>
                                          ))}
                                      </TableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Card>
              )}
              <Box sx={{ mb: 3 }} />
              <Divider />
              <Box sx={{ mb: 3 }} />
              <Typography>Поиск по строке</Typography>
              <Box sx={{ mb: 3 }} />
              <FormikProvider value={formik2}>
                  <Form noValidate autoComplete="off" onSubmit={handleSubmit2}>
                      <TextField
                          fullWidth
                          label="Строка"
                          {...getFieldProps2('search')}
                          error={Boolean(touched2.search && errors2.search)}
                          helperText={touched2.search && errors2.search}
                      />
                  </Form>
              </FormikProvider>
          </Container>
        </Page>
    );
}
