import axios from 'axios';

// ----------------------------------------------------------------------

//export const baseUrl = 'http://localhost/bakdent/public/api/v1';  // local
//export const baseUrl = 'http://79.141.77.50/dent/public/api/v1';    // test
//export const baseUrl = 'http://192.168.11.106/dent/public/api/v1';  // live
//export const baseUrl = 'http://192.168.88.241/dent/public/api/v1';  // server 3
export const baseUrl = 'https://back.edu.pirogov.dianapp.online/api/v1';  // edu

export const localUrl = 'http://localhost:5000'

const axiosInstanceBase = axios.create({
    baseURL: baseUrl
});

const axiosInstanceLocal = axios.create({
    baseURL: localUrl
});

const ApiClients = {
    axiosBase: axiosInstanceBase,
    axiosLocal: axiosInstanceLocal,
}

Object.values(ApiClients).forEach((instance) => instance.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
))

export default ApiClients;
