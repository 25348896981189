// material
import {
    Container,
    Card,
    Box, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Typography
} from '@mui/material';
// components
import Page from '../../components/Page';
import useSettings from "../../hooks/useSettings";
import HeaderBreadcrumbs from "../../components/HeaderBreadcrumbs";
import {PATH_DASHBOARD} from "../../routes/paths";
import {RootState, useDispatch, useSelector} from "../../redux/store";
import {
    indexFileThunk,
    updateDoctorsThunk,
    updateServicePricesThunk,
    updateServicesThunk
} from "../../redux/thunks/admin";
import {getLocalVersionThunk} from "../../redux/thunks/local";
import {useSnackbar} from "notistack";

export default function Admin() {
    const { themeStretch } = useSettings();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { isLoading, indexResult, dictUpdateResult } = useSelector((state: RootState) => state.admin)

    const handleIndexStart = () => {
        dispatch(indexFileThunk());
    };

    const handleUpdateDoctors = () => {
        dispatch(updateDoctorsThunk());
    };

    const handleUpdateServices = () => {
        dispatch(updateServicesThunk());
    };

    const handleUpdateServicePrices = () => {
        dispatch(updateServicePricesThunk());
    }

    const handleTestLocal = async () => {
        const result = await dispatch(getLocalVersionThunk());
        if(result.available) {
            enqueueSnackbar(`Локальный сервер доступен, версия ${result.data?.version}`, { variant: 'success' })
        }
        else {
            enqueueSnackbar(`Локальный сервер недоступен`, { variant: 'error' })
        }
    }

    return (
        <Page title="Администрирование">
          <Container maxWidth={themeStretch ? false : 'lg'}>
              <HeaderBreadcrumbs
                  heading="Администрирование"
                  links={[
                      { name: 'Главная', href: PATH_DASHBOARD.root },
                      { name: 'Администрирование', href: PATH_DASHBOARD.admin.root },
                  ]}
              />
              <Card sx={{ p: 3 }}>
                  <Button
                      variant="contained"
                      sx={{m:2}}
                      onClick={handleIndexStart}
                      disabled={isLoading}
                  >
                      Запустить индексирование файлов
                  </Button>
                  <Button
                      variant="contained"
                      sx={{m:2}}
                      onClick={handleUpdateDoctors}
                      disabled={isLoading}
                  >
                      Обновить докторов
                  </Button>
                  <Button
                      variant="contained"
                      sx={{m:2}}
                      onClick={handleUpdateServices}
                      disabled={isLoading}
                  >
                      Обновить услуги
                  </Button>
                  <Button
                      variant="contained"
                      sx={{m:2}}
                      onClick={handleUpdateServicePrices}
                      disabled={isLoading}
                  >
                      Обновить цены для плана
                  </Button>
                  <Button
                      variant="contained"
                      sx={{m:2}}
                      onClick={handleTestLocal}
                      disabled={isLoading}
                  >
                      Проверить локальный сервер
                  </Button>
              </Card>
              <Box sx={{ mb: 3 }} />

              {!isLoading && indexResult && indexResult.length > 0 && (
                  <Card>
                      <TableContainer sx={{ minWidth: 800 }}>
                          <Table>
                              <TableHead>
                                  <TableRow>
                                      <TableCell>
                                          &nbsp;
                                      </TableCell>
                                      <TableCell>
                                          новое
                                      </TableCell>
                                      <TableCell>
                                          индексировано ранее
                                      </TableCell>
                                  </TableRow>
                              </TableHead>
                              <TableBody>
                                  {indexResult.map((result) => (
                                      <TableRow
                                          key={result.prefix}
                                      >
                                          <TableCell component="th" scope="row">
                                              <Typography variant="subtitle2" noWrap>
                                                  {result.prefix}
                                              </Typography>
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                              <Typography variant="subtitle2" noWrap>
                                                  {result.addCount}
                                              </Typography>
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                              <Typography variant="subtitle2" noWrap>
                                                  {result.foundCount}
                                              </Typography>
                                          </TableCell>
                                      </TableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Card>
              )}
              {!isLoading && dictUpdateResult && dictUpdateResult.length > 0 && (
                  <Card>
                      <TableContainer sx={{ minWidth: 800 }}>
                          <Table>
                              <TableBody>
                                  {dictUpdateResult.map((dict) => (
                                      <TableRow
                                          key={dict.value}
                                      >
                                          <TableCell component="th" scope="row">
                                              <Typography variant="subtitle2" noWrap>
                                                  {dict.title}
                                              </Typography>
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                              <Typography variant="subtitle2" noWrap>
                                                  {dict.value}
                                              </Typography>
                                          </TableCell>
                                      </TableRow>
                                  ))}
                              </TableBody>
                          </Table>
                      </TableContainer>
                  </Card>
              )}
          </Container>
        </Page>
    );
}
