import {AppThunk} from "../store";
import ApiClients from "../../utils/axios";
import {IndexResult} from "../../@types/admin";
import {
    indexSuccess,
    hasError,
    startLoading,
    dictUpdateSuccess,
    infodentSelectSuccess,
    finishLoading
} from "../slices/admin";

const { axiosBase } = ApiClients;

export const indexFileThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.post<IndexResult[]>(`/file/index`);
        dispatch(indexSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateDoctorsThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.post<IndexResult[]>(`/dict/doctors/update`);
        dispatch(dictUpdateSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateServicesThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.post<IndexResult[]>(`/dict/services/update`);
        dispatch(dictUpdateSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const updateServicePricesThunk = (
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        await axiosBase.post<IndexResult[]>(`/dict/planservices/update`);
        dispatch(finishLoading());
    } catch (error) {
        dispatch(hasError(error));
    }
    return false;
}

export const infodentSelectListThunk = (
    query: string,
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        const response = await axiosBase.get(`/infodent?query=${query}`);
        dispatch(infodentSelectSuccess(response.data));
    } catch (error) {
        dispatch(hasError(error));
    }
}

export const infodentFindListThunk = (
    search: string,
):AppThunk => async dispatch => {
    dispatch(startLoading());
    try {
        await axiosBase.get(`/infodent/find?search=${search}`);
        dispatch(finishLoading());
    } catch (error) {
        dispatch(hasError(error));
    }
}