// material
import {IconButton, TableCell, TableRow, TextField} from '@mui/material';

import React, {ChangeEvent, useState} from "react";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import {Icon} from "@iconify/react";
import {PlanVariantLine} from "../../../../@types/plan";
import {fNumber} from "../../../../utils/formatNumber";

// ----------------------------------------------------------------------

type Props = {
    line: PlanVariantLine
    onChange: (line: PlanVariantLine) => void
    onRemove: () => void
};

export default function VariantLineRow({
    line,
    onChange,
    onRemove,
}: Props) {
    const [count, setCount] = useState<string>(String(line.count))

    const handleCountChange = (e: ChangeEvent<HTMLInputElement>) => {
        setCount(e.target.value)
        const count = Number(e.target.value)
        if(count !== line.count) {
            onChange({
                ...line,
                count,
            })
        }
    }

    return (
        <TableRow>
            <TableCell>{line.service.title}</TableCell>
            <TableCell>{fNumber(line.service.price)} р.</TableCell>
            <TableCell>
                <TextField
                    value={count}
                    onChange={handleCountChange}
                />
            </TableCell>
            <TableCell>
                <IconButton
                     onClick={() => onRemove()}
                >
                    <Icon icon={trash2Outline} width={24} height={24} />
                </IconButton>
            </TableCell>
        </TableRow>
    );
}
