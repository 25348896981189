import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import {
  UserManager, UserRole,
} from '../../@types/user';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  userList: UserManager[];
  roleList: UserRole[];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  userList: [],
  roleList: [],
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getRoleListSuccess(state, action) {
      state.isLoading = false;
      state.roleList = action.payload;
    },
  }
});

export const { startLoading, hasError, deleteUser, getUserListSuccess, getRoleListSuccess } = slice.actions;

export default slice.reducer;


