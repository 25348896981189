import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import clientReducer from './slices/client';
import adminReducer from './slices/admin';
import formulaReducer from './slices/formula';
import planReducer from './slices/plan';
import dicomReducer from './slices/dicom';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  client: clientReducer,
  admin: adminReducer,
  formula: formulaReducer,
  plan: planReducer,
  dicom: dicomReducer,
});

export { rootPersistConfig, rootReducer };
