import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import {
  Grid,
  Card,
  Stack,
  TextField,
} from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
// utils
// @types
import { User } from '../../../@types/account';
//

interface InitialState extends Omit<User, 'password' | 'id' > {
  afterSubmit?: string;
}

export default function AccountGeneral() {
  const { user } = useAuth();

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const formik = useFormik<InitialState>({
    enableReinitialize: true,
    initialValues: {
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      middle_name: user?.middle_name || '',
      email: user?.email,
      role: user?.role,
      role_name: user?.role_name,
      position: user?.position,
    },

    validationSchema: UpdateUserSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      /* try {
        updateProfile?.();
        enqueueSnackbar('Update success', { variant: 'success' });
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      } */
    }
  });

  const { handleSubmit, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={{ xs: 2, md: 3 }}>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth disabled label="Фамилия" {...getFieldProps('last_name')} />
                  <TextField fullWidth disabled label="Email" {...getFieldProps('email')} />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth disabled label="Имя" {...getFieldProps('first_name')} />
                  <TextField fullWidth disabled label="Роль" {...getFieldProps('role_name')} />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                  <TextField fullWidth disabled label="Отчество" {...getFieldProps('middle_name')} />
                  <TextField fullWidth disabled label="Должность" {...getFieldProps('position')} />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
