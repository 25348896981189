import Dialog from "@mui/material/Dialog";
import {
  Button, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

interface Props {
  title: string;
  text: string;
  open: boolean;
  onClose: (confirm: boolean) => void;
}

export default function ConfirmDialog({
    title,
    text,
    open,
    onClose,
}: Props) {

  return (
      <Dialog open={ open } onClose={() => onClose(false)}>
        <DialogTitle>{ title }</DialogTitle>
        <DialogContent>
          <DialogContentText>
            { text }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose(false)} autoFocus>Отмена</Button>
          <Button onClick={() => onClose(true)}>Подтвердить</Button>
        </DialogActions>
      </Dialog>
  );
}
