import { createSlice } from '@reduxjs/toolkit';
import {DicomFile} from "../../@types/files";

// ----------------------------------------------------------------------

type DicomState = {
    isLoading: boolean;
    error: boolean;
    fileList: DicomFile[];
};

const initialState: DicomState = {
    isLoading: false,
    error: false,
    fileList: [],
};

const slice = createSlice({
    name: 'dicom',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getFileListSuccess(state, action) {
            state.fileList = action.payload;
        },

        addFile(state, action) {
            const files = state.fileList.concat([action.payload]);
            state.fileList = files;
        },

        deleteFile(state, action) {
            state.fileList = state.fileList.filter(f => f.id !== action.payload);
        },
    }
});

export const { startLoading, hasError, getFileListSuccess, addFile, deleteFile} = slice.actions;

export default slice.reducer;


