import { createSlice } from '@reduxjs/toolkit';
import {Plan, PlanVariant, PlanService, PlanServiceGroup} from "../../@types/plan";

// ----------------------------------------------------------------------

type PlanState = {
    isLoading: boolean;
    error: boolean;
    planList: Plan[];
    variantList: PlanVariant[];
    serviceList: PlanService[];
    serviceGroupList: PlanServiceGroup[];
};

const initialState: PlanState = {
    isLoading: false,
    error: false,
    planList: [],
    variantList: [],
    serviceList: [],
    serviceGroupList: [],
};

const slice = createSlice({
    name: 'plan',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getPlanListSuccess(state, action) {
            state.isLoading = false;
            state.planList = action.payload;
        },

        addPlan(state, action) {
            state.planList = state.planList.concat([action.payload]);
        },

        removePlan(state, action) {
            state.planList = state.planList.filter(plan => plan.id !== action.payload.id);
        },

        addPlanItem(state, action) {
            state.planList = state.planList.map(plan => plan.id === action.payload.plan_id ? {
                ...plan,
                items: plan.items.concat([action.payload])
            } : plan)
        },

        editPlanItem(state, action) {
            state.planList = state.planList.map(plan => plan.id === action.payload.plan_id ? {
                ...plan,
                items: plan.items.map(planItem => planItem.id === action.payload.id ? action.payload : planItem)
            } : plan)
        },

        deletePlanItem(state, action) {
            state.planList = state.planList.map(plan => plan.id === action.payload.plan_id ? {
                ...plan,
                items: plan.items.filter(planItem => planItem.id !== action.payload.id)
            } : plan)
        },

        getPlanVariantListSuccess(state, action) {
            state.isLoading = false;
            state.variantList = action.payload;
        },

        addPlanVariant(state, action) {
            state.variantList = state.variantList.concat([action.payload])
        },

        editPlanVariant(state, action) {
            state.variantList = state.variantList.map(variant => variant.id === action.payload.id ? action.payload : variant)
        },

        deletePlanVariant(state, action) {
            state.variantList = state.variantList.filter(variant => variant.id !== action.payload.id)
        },

        getPlanServiceListSuccess(state, action) {
            state.isLoading = false;
            state.serviceList = action.payload;
        },

        getPlanServiceGroupListSuccess(state, action) {
            state.isLoading = false;
            state.serviceGroupList = action.payload;
        },
    }
});

export const { startLoading, hasError, getPlanListSuccess, addPlan, removePlan, addPlanItem, editPlanItem, deletePlanItem,
    getPlanVariantListSuccess, addPlanVariant, editPlanVariant, deletePlanVariant,
    getPlanServiceListSuccess, getPlanServiceGroupListSuccess } = slice.actions;

export default slice.reducer;


