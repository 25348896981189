import { useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
// material
import { Container } from '@mui/material';
// redux
import { useDispatch, useSelector, RootState } from '../../redux/store';
import {getRoleListThunk, getUserListThunk} from "../../redux/thunks/user";
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// hooks
import useSettings from '../../hooks/useSettings';
// components
import Page from '../../components/Page';
import HeaderBreadcrumbs from '../../components/HeaderBreadcrumbs';
import UserNewForm from '../../components/user/UserNewForm';

// ----------------------------------------------------------------------

export default function UserCreate() {
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id = '' } = useParams();
  const { userList, roleList } = useSelector((state: RootState) => state.user);
  const isEdit = pathname.includes('edit');
  const currentUser = userList.find((user) => (+user.id === +id));

  useEffect(() => {
    dispatch(getUserListThunk());
    dispatch(getRoleListThunk());
  }, [dispatch]);

  return (
    <Page title="Редактирование пользователя">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={!isEdit ? 'Создание пользователя' : 'Редактирование пользователя'}
          links={[
            { name: 'Главная', href: PATH_DASHBOARD.root },
            { name: 'Пользователи', href: PATH_DASHBOARD.user.list },
            { name: !isEdit ? 'Новый пользователь' : id }
          ]}
        />

        <UserNewForm
            isEdit={isEdit}
            currentUser={currentUser}
            roleList={roleList}
        />
      </Container>
    </Page>
  );
}
