import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { Form, FormikProvider, useFormik } from 'formik';
// material
import { LoadingButton } from '@mui/lab';
import {
  Card,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
  FormControlLabel, Box
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// @types
import {UserManager, UserRole} from '../../@types/user';
//
import {createUserThunk, editUserThunk} from "../../redux/thunks/user";
import {useDispatch} from "../../redux/store";

// ----------------------------------------------------------------------

type UserNewFormProps = {
  isEdit: boolean;
  currentUser?: UserManager;
  roleList: UserRole[];
};

export default function UserNewForm({ isEdit, currentUser, roleList }: UserNewFormProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({
    last_name: Yup.string().required('Фамилия обязательна для заполнения'),
    first_name: Yup.string().required('Имя обязательно для заполнения'),
    email: Yup.string().required('Email обязательно для заполнения').email(),
    role_id: Yup.string().required('Выберите роль пользователя'),
    position: Yup.string().required('Должность обязательна для заполнения'),
    newPassword: Yup.string()
        .matches(
            /^(?=.{5,})/,
            "Должен содержать минимум 5 символов"
        ),
    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Пароли не совпадают')
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      first_name: currentUser?.first_name || '',
      last_name: currentUser?.last_name || '',
      middle_name: currentUser?.middle_name || '',
      email: currentUser?.email || '',
      role_id: currentUser?.role.id || '',
      position: currentUser?.position || '',
      active: !!(currentUser === undefined || currentUser?.active),
      newPassword: '',
      confirmNewPassword: ''
    },
    validationSchema: NewUserSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const params = {
        ...values,
        active: values.active ? 1 : 0,
        password: values.newPassword.length > 0 ? values.newPassword : undefined,
      }
      const result = isEdit
          ? await dispatch(editUserThunk(currentUser?.id || '0', params))
          : await dispatch(createUserThunk(params));

      setSubmitting(false);
      if(result){
        resetForm();
        enqueueSnackbar(!isEdit ? 'Пользователь успешно добавлен' : 'Прользователь успешно сохранен', { variant: 'success' });
        navigate(PATH_DASHBOARD.user.list);
      }
      else {
        enqueueSnackbar("Ошибка выполнения запроса к серверу", { variant: 'error' });
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } =
    formik;

  return (
    <FormikProvider value={formik}>
      <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <FormControlLabel
                  labelPlacement="start"
                  control={<Switch {...getFieldProps('active')} checked={values.active} />}
                  label={
                    <>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Активность
                      </Typography>
                    </>
                  }
                  sx={{ mx: 0, width: 1, justifyContent: 'space-between' }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                    fullWidth
                    label="Фамилия"
                    {...getFieldProps('last_name')}
                    error={Boolean(touched.last_name && errors.last_name)}
                    helperText={touched.last_name && errors.last_name}
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    placeholder="name@e-mail.ru"
                    {...getFieldProps('email')}
                    error={Boolean(touched.email && errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                      fullWidth
                      label="Имя"
                      {...getFieldProps('first_name')}
                      error={Boolean(touched.first_name && errors.first_name)}
                      helperText={touched.first_name && errors.first_name}
                  />
                  <TextField
                      select
                      fullWidth
                      label="Роль"
                      {...getFieldProps('role_id')}
                      SelectProps={{ native: true }}
                      error={Boolean(touched.role_id && errors.role_id)}
                      helperText={touched.role_id && errors.role_id}
                  >
                    <option value="" />
                    {roleList.map(role => (
                        <option key={role.id} value={role.id}>
                          {role.name}
                        </option>
                    ))}
                  </TextField>
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                      fullWidth
                      label="Отчество"
                      {...getFieldProps('middle_name')}
                      error={Boolean(touched.middle_name && errors.middle_name)}
                      helperText={touched.middle_name && errors.middle_name}
                  />
                  <TextField
                      fullWidth
                      label="Должность"
                      {...getFieldProps('position')}
                      error={Boolean(touched.position && errors.position)}
                      helperText={touched.position && errors.position}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={8}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
                  <TextField
                      {...getFieldProps('newPassword')}
                      fullWidth
                      autoComplete="on"
                      type="password"
                      label="Новый пароль"
                      error={Boolean(touched.newPassword && errors.newPassword)}
                      helperText={
                        (touched.newPassword && errors.newPassword) || 'Пароль должен быть длиной минимум 5'
                      }
                  />

                  <TextField
                      {...getFieldProps('confirmNewPassword')}
                      fullWidth
                      autoComplete="on"
                      type="password"
                      label="Повторить новый пароль"
                      error={Boolean(touched.confirmNewPassword && errors.confirmNewPassword)}
                      helperText={touched.confirmNewPassword && errors.confirmNewPassword}
                  />
                </Stack>
              </Stack>
            </Card>
          </Grid>
        </Grid>

        <Box sx={{ mb: 3 }} />
        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {!isEdit ? 'Создать' : 'Сохранить'}
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
