// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  clients: getIcon('ic_kanban'),
  user: getIcon('ic_user'),
  admin: getIcon('ic_dashboard'),
};

const sidebarConfig = [
  {
    subheader: 'Общее',
    items: [
      { title: 'Пациенты', path: PATH_DASHBOARD.clients.root, icon: ICONS.clients },
    ]
  },
  {
    subheader: 'Администрирование',
    items: [
      { title: 'Пользователи', path: PATH_DASHBOARD.user.list, icon: ICONS.user },
      { title: 'Функции', path: PATH_DASHBOARD.admin.root, icon: ICONS.admin },
      { title: 'Инфодент', path: PATH_DASHBOARD.infodent.root, icon: ICONS.clients },
      { title: 'Варианты лечения', path: PATH_DASHBOARD.planadmin.root, icon: ICONS.admin },
    ]
  },
];

export default sidebarConfig;
