// ----------------------------------------------------------------------
import {Status} from "./dentalFormula";

export const PLAN_TYPES = [
  { name: 'cheap', title: 'бюджетный' },
  { name: 'full', title: 'максимальный' },
];

export enum PlanItemStatus {
  DRAFT = 'draft',
  ACCEPT = 'accept',
  PLAN = 'plan',
  FACT = 'fact',
}

export enum PlanVariantType {
  CHEAP = 'cheap',
  FULL = 'full',
}

export type PlanItemLine = {
  id?: number;
  plan_variant_line_id?: number;
  title: string;
  price: number;
  count: number;
  discount: number;
  sort: number;
}

export type PlanItemTooth = {
  id?: number;
  tooth_id: number;
}

export type PlanItem = {
  id?: number;
  plan_id: number;
  variant_id?: number;
  title: string;
  status: PlanItemStatus;
  teeth?: PlanItemTooth[];
  lines: PlanItemLine[];
};

export type Plan = {
  id: number;
  client_id: number;
  title: string;
  items: PlanItem[];
};

export type PlanServiceGroup = {
  id: number;
  title: string;
  parent_id?: number;
};

export type PlanService = {
  id: number;
  code: string;
  title: string;
  price: number;
  group_id: number;
};

export type PlanVariantLine = {
  id?: number;
  service: PlanService;
  count: number;
  sort: number;
}

export type PlanVariant = {
  id?: number;
  title: string;
  type: PlanVariantType;
  status?: Status;
  lines: PlanVariantLine[];
}
