// material
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  return (
    <Box sx={{ ...sx }}>
      <img src="/static/icons/dent-logo.svg" alt="logo"/>
    </Box>
  );
}
