import { createSlice } from '@reduxjs/toolkit';
import {Status, Tooth} from "../../@types/dentalFormula";

// ----------------------------------------------------------------------

type FormulaState = {
    isLoading: boolean;
    error: boolean;
    toothList: Tooth[];
    statusList: Status[];
};

const initialState: FormulaState = {
    isLoading: false,
    error: false,
    toothList: [],
    statusList: [],
};

const slice = createSlice({
    name: 'formula',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getToothListSuccess(state, action) {
            state.isLoading = false;
            state.toothList = action.payload;
        },

        getStatusListSuccess(state, action) {
            state.isLoading = false;
            state.statusList = action.payload;
        },
    }
});

export const { startLoading, hasError, getToothListSuccess, getStatusListSuccess } = slice.actions;

export default slice.reducer;


